import Vue from 'vue'
import VueRouter from 'vue-router'
import {sessionGetKey} from '@/utils/sessionStorage.js'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    hidden: true, 
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    hidden: true, 
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    hidden: true,
    component: () => import('@/views/layout/layout.vue'),
  },
  {
    path: '/adminLayout',
    name: 'adminLayout',
    hidden: true,
    redirect: '/home',
    component: () => import('@/views/adminLayout/adminLayout.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, form, next) => {
  const token = sessionGetKey('token')
  if(token || to.name === 'login'){
    next();
  }else{
    next('/login')
  }

  if(to.path == form.path){
    return false
  }

  
  // console.log(getCookie('farm_token'));
  // let token = getCookie('farm_token');
  // if (token || to.name === 'login') {
  //   next();
  // } else {
  //   alert('请先登录,不然无法访问');
  //   next('/login');
  // }
});

export default router