import { router1, merRouter,chantRouter} from "@/router/modules/routerData.js";
import notFound from "@/router/modules/notFound.js";
import router from "@/router/index";
import { permissionRouter } from "@/utils/permissionRouter.js";
import api from '@/api/apiUrl.js'
import request from "@/api/request.js";
import {setStorage,clearStorage} from '@/utils/LocalStorage.js'
import {sessionSet} from '@/utils/sessionStorage.js'


const state = {
};

const mutations = {
    LOGIN({ state,commit,dispatch},{token,account,pass,checked,role,name,id,phone,time,app_num,platformId}) {
        console.log(checked,'checked');
        if(checked){
            setStorage('account',account,168*60)
            setStorage('pass',pass,168*60)
        }else{
            clearStorage()
        }
        sessionSet('token',token)
        console.log(role,'role');
        sessionSet('role',role)
        sessionSet('userName',name)
        sessionSet('ID',id)
        sessionSet('phone',phone)
        sessionSet('time',time)
        sessionSet('app_num',app_num)
        console.log(platformId,'platformId');
        sessionSet('platformId',platformId)
    },
    
};

const actions = {
    // 动态添加路由
    changeRoles({ commit, dispatch }, role) {
        if (role == 0) {
            const permissionRouterList = permissionRouter(router1, role);
            router.addRoutes(permissionRouterList);
            console.log(permissionRouterList, "permissionRouterList");
        }else if (role ==1){
            const chantRouterList = permissionRouter(chantRouter, role);
            router.addRoutes(chantRouterList);
            console.log(chantRouterList, "chantRouterList");
        }
         else if (role == 3 || role == 2) {
            const permission = permissionRouter(merRouter, role);
            router.addRoutes(permission);
            console.log(permission, "permission");
        }
        router.addRoutes(notFound);
    },
    login({ commit, dispatch }, data) {
        const account = data.account.name;
        const pass = data.account.password;
        const checked = data.checked;
        request({
            url: api.login.login,
            method: 'post',
            data:{
                number:account,
                psw:pass
            }
        }).then((res) => {
            commit('LOGIN',{
                token:res.data.token,
                account:account,
                pass:pass,
                checked:checked,
                role:res.data.auth,
                name:res.data.name,
                id:res.data.id,
                phone:res.data.phone,
                time:res.data.time,
                app_num:res.data.app_num,
                platformId:res.data.is_platform
            })
            dispatch('changeRoles', res.data.auth).then(resss => {
                const role = res.data.auth
                if(role == 0){
                    router.push('/erchantMent')
                }else if(role == 1){
                    router.push('/appletCenter')
                }else if(role == 3 || role== 2){
                    router.push('/home')
                }
            })
        });
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
