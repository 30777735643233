<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import {sessionGetKey} from '@/utils/sessionStorage.js'
  export default {
    mounted() {
      let role = sessionGetKey('role')
      if (role !==null && role!=='') {
        this.$store.dispatch('login/changeRoles', role)
      }
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: PingFang SC-Semibold, PingFang SC;
  }
</style>