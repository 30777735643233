<template>
    <div>
        <el-dialog :visible.sync="dialogVal" width="20%" center :show-close="false">
            <span slot="title" class="title">
                <img class="close-icon" src="@/assets/images/icon/close-black.png" @click="cancel">
            </span>
            <div class="content">{{title}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props:{
            dialogVal:{
                type:Boolean,
                default:false
            },
            title:{
                type:String,
                default:'您确定要删除该行吗?'
            },
            appId:{
                type:String|Array|Object
            }
        },
        methods:{
            confirm(){
                const that =this
                this.$emit('dialogChange',1,that.appId)
            },
            cancel(){
                this.$emit('dialogChange',2)
            }
        }
    }
</script>

<style lang="scss" scoped>

    .title{
        .close-icon{
            float: right;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .content{
        color: #333333;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }

    ::v-deep .el-dialog{
        border-radius: 16px;
    }

    ::v-deep .el-dialog__wrapper{
        display: flex;
        align-items: center;
    }
</style>