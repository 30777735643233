/*
顶部
*/
<template>
    <div class="main cross-center">
        <div class="topbar main-between">
            <div class="logo cross-center">
                <img src="@/assets/images/login/logo.png">
                <span>后台管理系统</span>
            </div>
            <div class="sign-out cross-center">
                <img class="portrait" src="@/assets/images/login/logo.png">
                <el-dropdown trigger="click">
                    <div class="cross-center">
                        <span>{{userName}}</span>
                        <img class="exit-icon" src="@/assets/images/icon/arrow-up-black.png">
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item>修改密码</el-dropdown-item> -->
                        <el-dropdown-item @click.native="logOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    import {sessionClear} from '@/utils/sessionStorage.js'
    export default {
        props:{
            userName:{
                type:String,
                default:''
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            logOut(){
                sessionClear()
                this.$router.push('/login')
            },
            // 展开
            open() {

            },
            // 退出登录
            loginExit() {
                // this.$cookieStore.clearCookie()
                this.$router.push('/login')
            }
        },
    }
</script>

<style lang="scss" scoped>
    .main {
        width: 100%;
        height: 100%;
        padding: 0 38px;
    }

    .topbar {
        width: 100%;

        .logo {
            img {
                width: 50px;
                height: 50px;
            }

            span {
                margin-left: 10px;
                color: #333333;
                font-size: 22px;
                font-weight: 600;
            }
        }

        .sign-out {
            .portrait {
                width: 44px;
                height: 44px;
            }

            span {
                margin: 0 10px;
                // font-size: 20px;
                cursor: pointer;
            }

            .exit-icon {
                cursor: pointer;
                width: 24px;
                height: 24px;
            }
        }
    }
</style>