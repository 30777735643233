const formdata='formdata'
const formMentinfo='formMentinfo'
const state = {
    getformtext:JSON.parse(window.localStorage.getItem(formMentinfo)),
      getlist: JSON.parse(window.localStorage.getItem(formdata))||[],
      active: 0
    };
    const mutations = {
      settingForm(state,val){
        if(val.text){
          //
          state.getlist= JSON.parse(val.text)
          this.commit('savestore')
        }
        console.log(val.text,'------')
        if(val.id&&val.name){
          window.localStorage.setItem(formMentinfo,JSON.stringify(val))
          state.getformtext=val
        }
      
      },
      setactiveStore(state, val) {
        state.active = val;
      },
      putlistStore(state, val) {
        let a1 = [];
        let a2 = [];
        state.getlist.forEach((e, i) => {
          if (i >= state.active) {
            a1.push(e);
          } else {
            a2.push(e);
          }
        });
        console.log(a1, a2, "-----");
        state.getlist = [...a2, val, ...a1];
        console.log(state.active, val, state.getlist);
      },
      savestore(state){
        window.localStorage.setItem(formdata,JSON.stringify(state.getlist))
      },
      setlistStore(state, val) {
        console.log("---------------");
        console.log(state.active, state.getlist);
        console.log(val, state.getlist[state.active]);
        console.log("---------------");
   state.getlist[state.active].data=val.data
        state.getlist.forEach((value, index) => {
          if (state.active === index) {
            value.id = val.id;
            value.data = val.data;
          }
        });
        
       
      },
      addlistStore(state, val) {
        state.getlist.push({
          name: val.name,
          id: "",
          data: {}
        });
        // window.localStorage.setItem('bbb',JSON.stringify(state.getlist))
      },
      addlistStoredata(state, val){
        state.getlist.filter((value,i)=>{
          if(value.name==val.name&&i==state.active){
            console.log(val,'------')
          }
        })
       
      },
      removelistStore(state, val) {
        state.getlist.splice(val, 1);
        console.log(val);
      },
      createlistStroe(state, val){
       
      }
    };
    const actions = {};
    const getters = {};
    export default {
      state,
      mutations,
      actions,
      getters
    };
    