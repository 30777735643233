import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import api from './api/apiUrl';
import * as echarts from 'echarts';
import request from '@/api/request.js'

import '@/assets/css/index.scss'
import 'normalize.css/normalize.css'

import infoTable from "@/components/infoTable/infoTable.vue"
import pagination from "@/components/pagination/pagination.vue"
import deldialog from "@/components/dialog/deldialog.vue"


import VueQuillEditor from 'vue-quill-editor'

import VueUeditorWrap from 'vue-ueditor-wrap'

// import  '../public/tinymce/tinymce.min.js'
import '../public/UEditor/ueditor.config.js'
import '../public/UEditor/ueditor.all.js'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueQuillEditor)
Vue.use(VueUeditorWrap)
Vue.prototype.$api = api
Vue.prototype.$echarts = echarts
Vue.prototype.$request = request
Vue.prototype.$uploadurl = 'https://tw.huayeyun.cn/api/Admin/upload'
Vue.component('infoTable', infoTable)
Vue.component('infoPage', pagination)
Vue.component('deldialog',deldialog)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
