<template>
    <div>
        <div class="wrapper">
            <el-container>
                <el-header>
                    <!-- 头部区域 -->
                    <topBar :userName="userName"></topBar>
                </el-header>
                <el-container class="bggrey">
                    <!-- 侧边栏区域 -->
                    <el-aside>
                        <silderBar :perRouterList="perRouterList"></silderBar>
                    </el-aside>
                    <!-- 主体区域 -->
                    <el-main>
                        <router-view></router-view>
                    </el-main>
                </el-container>
            </el-container>
        </div>
    </div>
</template>

<script>
    import topBar from '@/components/topBar/topBar.vue'
    import silderBar from '@/components/silderBar'
    import {router1,merRouter,chantRouter} from '@/router/modules/routerData.js'
    import {permissionRouter,sidebarDisplay} from '@/utils/permissionRouter.js'
    import {sessionGetKey} from '@/utils/sessionStorage.js'
    import deepCopy from '@/utils/deepCopy'
    export default {
        components: {
            topBar,
            silderBar
        },
        data() {
            return {
                perRouterList: [],
                userName:''
            }
        },
        created(){
            this.getsilderBar()
            this.getHeader()
        },
        mounted() {
        },
        methods: {
            getsilderBar() {
                const roleType = sessionGetKey('role')
                console.log(roleType,'roleType');
                let list = [];
                if (roleType == 0) {
                    list = sidebarDisplay(router1, roleType)
                }else if (roleType==1){
                    list = sidebarDisplay(chantRouter, roleType);
                }
                else if ( roleType == 2 || roleType == 3) {
                    list = sidebarDisplay(merRouter, roleType)
                }
                this.perRouterList = deepCopy(list)
                console.log(this.perRouterList, 'this.perRouterList')
            },
            getHeader(){
                const userName = sessionGetKey('userName')
                if(userName){
                    this.userName = userName
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .el-header {
        padding: 0;
        height: 88px !important;
    }

    .bggrey {
        background-color: #f3f3f3;
    }

    .el-aside {
        // background-color: red;
        height: calc(100vh - 88px);
        width: 230px !important;
        background-color: #1e2662;
    }

    /* 让滚动条隐藏 */
    .el-aside::-webkit-scrollbar {
        width: 0 !important
    }

    .el-main {
        background-color: #F5F6FA;
        padding: 0;
        height: calc(100vh - 88px);
    }
</style>