/*
 * @Author: 喀喀喀 1246444836@qq.com
 * @Date: 2022-07-18 09:00:21
 * @LastEditors: 喀喀喀 1246444836@qq.com
 * @LastEditTime: 2022-07-20 14:24:28
 * @FilePath: /xinxihoutai/src/api/apiUrl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const api ={
    //登录
    login:{
        login:'/Admin/login'
    },
    custorserver:{
        setkf:'/AdminFun/setkf',
        getkf:'/AdminFun/getkf'
    },
    //小程序中心
    appMall:{
        getApp:'/User/getapp',
        setApp:'/User/setapp',
        setstatus:'/User/setstatus',
        delapp:'/User/delapp',
        addapp:'/User/addapp',
        getweixin:'/Index/getsetting',
        setweixin:'/Index/setsetting'
    },
    //商户管理
    merchantMent:{
        getusers:'/AdminFun/getusers',
        delUsers:'/AdminFun/delusers',
        addUser:'/AdminFun/adduser',
        editUser:'/AdminFun/setting'
    },
    //公告管理
    notice:{
        getNot:'/AdminFun/getnotice',
        addNot:'/AdminFun/addnotice',
        editNot:'/AdminFun/setnotice',
        delNot:'/AdminFun/delnotice'
    },
    //回收站
    recycleBin:{
        getRecycle:'/User/getrecycle',
        delRecycle:'/User/delrecycle',
        recovery: '/User/recycle'
    },
    // 首页数据
    home:{
        home:'/Index/index'
    },
    //轮播图
    rotantion:{
        getRotan:'/Article/getipic',
        addRotan:'/Article/addipic',
        editRotan:'/Article/setipic',
        delRotan:'/Article/delipic'
    },
    // 产品列表
    good:{
        getGood:'/Good/index',
        addGood:'/Good/addgood',
        delGood:'/Good/delgood',
        editGood:'/Good/setgood'
    },
    // 产品分类
    goodClass:{
        getClass:'/Good/getcategroy',
        addClass:'/Good/addcategroy',
        editClass:'/Good/setcategroy',
        delClass:'/Good/delcategroy'
    },
    // 产品参数模版
    goodTemplate:{
        getTemp:'/Good/gettemplate',
        addTemp:'/Good/addtemplate',
        editTemp:'/Good/settemplate',
        delTemp:'/Good/deltemplate'
    },
    // 表单
    formApi:{
        addform:'/Article/addform',
        delform:'/Article/delform',
        getform:'/Article/getform',
        editform:'/Article/setform',
        searchorder:'/Order/searchorder',
        delorder:'/Order/delOrder',
        setorder:'/Order/setOrder'
    },
     // 文章
    articleApi:{
        addarticle:'/Article/addarticle',
        delarticle:'/Article/delarticle',
        // editarticle:'/Article/setform',
        getarticle:'/Article/getarticle',
        setarticle:'/Article/setarticle',
        getarticlelevel:'/Article/getarticlelevel'
    },
    //员工
    staff:{
        addStaff:'/Staff/addstaff',
        getStaff:'/Staff/getstaff',
        delStaff:'/Staff/delstaff',
        editStaff:'/Staff/setstaff'
    },
    // 角色管理
    role:{
        addRole:'/Actor/addactor',
        getRole:'/Actor/getactor',
        editRole:'/Actor/setactor',
        delRole:'/Actor/delactor'
    }
}

export default api;