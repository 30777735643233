/*
  二次封装element表格
*/
<template>
<!-- class="table-z" 
min-height="400px"
-->
  <div >
    <el-table
      v-loading="tableLoading"
      ref="dataTable"
      :data="tableData"
      style="width: 100%"
      class="table"
      header-cell-class-name="table-header"
      highlight-current-row
      @selection-change="selectLine"
      :height="height"
      :row-class-name="tableRowClassName"
    >
      <!-- 多选框 -->
      <el-table-column v-if="selection" type="selection" width="55" :align="align"></el-table-column>
      <!-- 文本数据渲染 :fixed="item.slotName=='operation'?'right':false"-->
      <template v-for="(item) in tableHead"> 
        <el-table-column
          v-if="item.columnType==='slot'"
          :prop="item.field"
          :label="item.label"
          :key="item.field"
          :width="item.width"
          :align="item.align"
          :sortable="item.sort"
        >
          <template slot-scope="scope">
            <slot :name="item.slotName" :data="scope" />
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :fixed="item.slotName=='leftfixed'?'left':false"
          :sortable="item.sort"
          :prop="item.field"
          :label="item.label"
          :key="item.field"
          :width="item.width"
          :align="align"
        ></el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "e-table",
  components: {},
  /**
   * @name:
   * @test: test font
   * @msg:
   * @param {
   *  接收参数:
   *      tableHeadConfig           列的名称、接收值     |     Array
   *        label         列的名称                      |     String
   *        field         列的对应值                    |     String
   *        columnType    定义当前列为插槽    |   slot   |     String
   *        slotName      定义当前列插槽的名字           |     String
   *        width         定义当前列的宽度               |     String
   *  示例：配置
   *      tableHeadConfig:[
   *              {
   *                label       : "缩略图",
   *                field       : "skuName",
   *                columnType  : "slot",
   *                slotName    : "thumbnail",
   *                width       : 240
   *              }
   *            ]
   *
   *      tableLoadData     异步获取的table文本数据信息
   *      align             表格单元格内容排列顺序      left|center|right
   *      selection         表格是否可多选
   *      height            表格默认撑开高度
   *  事件：
   *      获取当前选中行
   *      调用页面用  @selectLine="xxx" 进行监听处理
   * }
   * @return:
   */
  props: {
    tableHeadConfig: {
      type: Array,
      default: () => {
        return [
          {
            label: "skuId",
            field: "skuId"
          },
          {
            label: "商品名称",
            field: "skuName"
          },
          {
            label: "缩略图",
            columnType: "slot",
            slotName: "thumbnail"
          },
          {
            label: "库存数量",
            field: "onStockNum"
          },
          {
            label: "码类型",
            field: "hasUniCode"
          },
          {
            label: "状态",
            field: "status",
            columnType: "slot",
            slotName: "status"
          },
          {
            label: "操作",
            columnType: "slot",
            slotName: "operation"
          }
        ];
      }
    },
    tableLoadData: {
      type: Array,
      default: () => {
        return [
          {
            skuId: "111",
            skuName: "222"
          }
        ];
      }
    },
    align: {
      type: String,
      default: "left"
    },
    selection: {
      type: Boolean,
      default: true
    },
    height: {
      type:  String,
     
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    
    };
  },
  created() {},
  mounted() {},
  methods: {
    selectLine(val) {
      this.$emit('selectLine',val);   
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    },
  },
  computed: {
    tableData() {
      return this.tableLoadData;
    },
    tableHead() {
      return this.tableHeadConfig;
    }
  }
};
</script>
<style >
.table{
    font-size: 16px;
    color: #333;
    font-weight: 400
    
}

.table-header{
   /* background-image:linear-gradient(#05C78A,#00E6A0) !important; */
   background-color: #EDEFFF !important;
   color: #333333;
   font-size: 16px;
}
.el-table .warning-row {
  /* background-color: #F5F6FA; */
}

</style>
