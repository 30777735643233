import axios from "axios";
import store from "@/store/index.js";
import {sessionGetKey} from '@/utils/sessionStorage.js'
import {
    MessageBox,
    Message
} from 'element-ui'

const request = axios.create({
    baseURL: '/api',
    timeout: 5000
})
//发送网络请求


//请求拦截器
request.interceptors.request.use(config => {
        const token = sessionGetKey('token');
        if (token) {
            config.headers.token = `${token}`
        }
        // 1.比如config中的一些信息不符合服务器的要求
        // 2.比如每次发送网络请求时，都希望在界面中显示一个请求的图标
        // 3.某些网络请求(比如登录(token))，必须携带一些特殊的信息
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

//响应时拦截
request.interceptors.response.use(res => {
    if (res.data.code !== 0 ) {
        Message({
            message: res.message || 'Error',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(new Error(res.message || 'Error'))
    } else {
        return res.data
    }
}, error => {
    // console.log(error.response.data.message)
    let msg=''
    if(error.response.data&&error.response.data.message){
msg=': '+error.response.data.message
    }
    Message({
        message: `${error.message}${msg}`,
        type: 'error',
        duration: 5 * 1000
    })
    return Promise.reject(error)
})

export default request