/*
侧边导航栏
*/
<template>
  <div class="sidebar-container">
    <template v-for="(item, index) in perRouterList">
      <div :key="index" class="menu-item-group" v-if="(item.children && hasName(item)) && !item.hidden">
        <div class="menu-title main-between cross-center" @click="open(item,index)">
          <div class="cross-center">
            <img class="item-img" :src="item.meta.icon">
            <span>{{item.meta.title}}</span>
          </div>
          <img class="group-img" src="@/assets/images/icon/arrow-up-white.png"
            :class="!item.isOpen?'icon-right0':'icon-right1'">
        </div>
        <div class="warp-top" :style="[{height: !item.isOpen ? '0px' : 50 * item.children.length + 'px'}]">
          <menu-item v-for="child in item.children" :key="compilePath(item.path,child.path)" :child="child"
            :basePath="item.path" />
        </div>
      </div>
      <menu-item :key="index" :child="item.children[0]" v-else-if="item.children && !item.hidden" :basePath="item.path"
        only="only"/>
    </template>
  </div>
</template>
<script>
  // import router1 from '@/router/modules/routerData.js'
  // import merRouter from '@/router/modules/merRouter.js'
  // import {
  //   permissionRouter
  // } from '@/utils/permissionRouter.js'
  import path from 'path'
  import MenuItem from './item.vue'
  export default {
    props:{
      perRouterList:{
        type:Array,
        default:function(){
          return []
        }
      }
    },
    data() {
      return {
      }
    },
    // watch:{
    //   perRouterList(newVal,oldVal){
    //     console.log('perRouterList',newVal)
    //   }
    // },
    components: {
      MenuItem
    },
    mounted() {
    },
    computed: {
      activeMenu() {
        return this.$route.path
      },
      perRouterList2(){
        return perRouterList
      }
    },
    methods: {
      // 拼接路由
      compilePath(itempath, childpath) {
        return path.resolve(itempath, childpath)
      },
      // 判断有没有路由中有咩有name
      hasName(item) {
        return item.name ? true : false
      },
      // 展开和收起
      open(item, index) {
        item.isOpen = !item.isOpen
        this.$set(this.perRouterList, `${index}`, this.perRouterList[index])
      },
      
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar-container {
    width: 100%;
    padding: 15px 0;
  }

  .menu-item-group {

    .menu-title {
      padding:0 15px;
      height: 50px;

      .item-img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }

      span {
        vertical-align: middle;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }

      .group-img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
        transform-origin: center center;
        /*设置图片旋转的中心点为左下角*/
        -webkit-transition: all 0.7s;
        transition: all 0.7s;
      }
    }
  }

  .icon-right1 {
    transform: rotate(-180deg);
  }

  .icon-right0 {
    transform: rotate(0deg);
  }

  .warp-top {
    -webkit-transition: height 0.8s;
    transition: height 0.8s;
    overflow: hidden;
  }
</style>