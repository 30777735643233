import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login.js'
import formdiy from './modules/formdiy.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters:{
    
  },
  modules: {
    login,
    formdiy
  }
})
