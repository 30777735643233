// 想要动态添加的路由信息
// permission是权限,权限无法访问

import layout from '@/views/layout/layout.vue'
import adminLayout from '@/views/adminLayout/adminLayout.vue'
// 管理员
export const router1 = [{
    path: '/erchantMent',
    name: 'erchantMent',
    meta: {
      title: '商户管理',
      icon: require('@/assets/images/icon/merchant-manage.png'),
      permission: [0]
    },
    redirect: '/erchantMent/statistics',
    component: layout,
    children: [{
        path: 'statistics',
        name: 'statistics',
        meta: {
          title: '商户统计',
          permission: [0]
        },
        component: () => import('@/views/erchantMent/statistics/statistics.vue'),
      },
      {
        path: 'list',
        name: 'list',
        meta: {
          title: '商户列表',
          permission: [0]
        },
        component: () => import('@/views/erchantMent/list/list.vue'),
      },
      {
        path: 'list/editlist',
        name: 'editlist',
        hidden: true,
        meta: {
          title: '商户编辑',
          permission: [0]
        },
        component: () => import('@/views/erchantMent/list/listEdit.vue'),
      },
      {
        path: 'newSub',
        name: 'newSub',
        meta: {
          title: '新增子商户',
          permission: [0]
        },
        component: () => import('@/views/erchantMent/newSub/newSub.vue'),
      }
    ]
  },
  {
    path: '/appletCenter',
    name: 'appletCenter',
    meta: {
      title: '小程序中心',
      icon: require('@/assets/images/icon/app-core.png'),
      permission: [0]
    },
    redirect: '/erchantMent/statistics',
    component: layout,
    children: [{
        path: 'appMerchant',
        name: 'appMerchant',
        meta: {
          title: '小程序商户',
          permission: [0]
        },
        component: () => import('@/views/appletCenter/appMerchant/appMerchant.vue'),
      },
      {
        path: 'recycleBin',
        name: 'recycleBin',
        meta: {
          title: '回收站',
          permission: [0]
        },
        component: () => import('@/views/appletCenter/recycleBin/recycleBin.vue'),
      }
    ]
  },
  {
    path: '/notice',
    meta: {
      permission: [0]
    },
    redirect: '/notice/notList',
    component: layout,
    children: [{
        path: 'notList',
        name: 'notList',
        meta: {
          title: '公告管理',
          icon: require('@/assets/images/icon/app-core.png'),
          permission: [0]
        },
        component: () => import('@/views/noticeMent/noticeList.vue'),
      },
      {
        path: 'notList/addNot',
        name: 'addNot',
        hidden: true,
        meta: {
          title: '添加公告',
          permission: [0]
        },
        component: () => import('@/views/noticeMent/noticeAdd.vue'),
      }
    ]
  },
  {
    path: '/cssetting',
    meta: {
      permission: [0]
    },
    redirect: '/cssetting',
    component: layout,
    children: [{
        path: 'cssetting',
        name: 'cssetting',
        meta: {
          title: '客服设置',
          icon: require('@/assets/images/icon/app-core.png'),
          permission: [0]
        },
        component: () => import('@/views/csSettings/csSettings.vue'),
      }
    ]
  },
  {
    path: '/home',
    component: adminLayout,
    hidden: true,
    meta: {
      permission: [0],
    },
    redirect: '/home/dashboard',
    children: [{
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/homepage/homepage.vue'),
      meta: {
        title: '首页',
        icon: require('@/assets/images/icon/home-page.png'),
        permission: [0]
      }
    }]
  },
  {
    path: '/rotation',
    hidden: true,
    meta: {
      permission: [0]
    },
    redirect: '/rotation/rotation',
    component: adminLayout,
    children: [{
        path: 'rotation',
        name: 'rotation',
        meta: {
          title: '轮播图片',
          icon: require('@/assets/images/icon/rotation-chart.png'),
          permission: [0]
        },
        component: () => import('@/views/rotation/rotation.vue'),
      },
      {
        path: 'edit',
        hidden: true,
        name: 'edit',
        meta: {
          title: '轮播图修改',
          permission: [0]
        },
        component: () => import('@/views/rotation/editRata.vue')
      },
      {
        path: 'add',
        hidden: true,
        name: 'add',
        meta: {
          title: '轮播图添加',
          permission: [0]
        },
        component: () => import('@/views/rotation/addRata.vue')
      },
    ]
  },
  {
    path: '/formMent',
    name: 'formMent',
    hidden: true,
    meta: {
      title: '表单管理',
      icon: require('@/assets/images/icon/form-ment.png'),
      permission: [0]
    },
    redirect: '/formMent/formMent',
    component: adminLayout,
    children: [{
        path: 'formMent',
        name: 'formMent',
        meta: {
          title: '表单管理',
          permission: [0]
        },
        component: () => import('@/views/formMent/formMent.vue'),
      },
      {
        path: 'formRecord',
        name: 'formRecord',
        meta: {
          title: '表单记录',
          permission: [0]
        },
        component: () => import('@/views/formMent/formRecord.vue'),
      },
      {
        path: 'editform',
        name: 'editform',
        hidden: true,
        meta: {
          title: '表单编辑',
          permission: [0]
        },
        component: () => import('@/views/formMent/editform.vue')
      },
    ]
  },
  {
    path: '/productMent',
    name: 'productMent',
    hidden: true,
    meta: {
      title: '产品管理',
      icon: require('@/assets/images/icon/product-ment.png'),
      permission: [0]
    },
    redirect: '/productMent/list',
    component: adminLayout,
    children: [{
        path: 'list',
        name: 'list',
        meta: {
          title: '产品列表',
          permission: [0]
        },
        component: () => import('@/views/productMent/productList/productList.vue'),
      },
      {
        path: 'list/addlist',
        name: 'addlist',
        hidden: true,
        meta: {
          title: '添加产品',
          permission: [0]
        },
        component: () => import('@/views/productMent/productList/productAdd.vue'),
      },
      {
        path: 'class',
        name: 'class',
        meta: {
          title: '产品分类',
          permission: [0]
        },
        component: () => import('@/views/productMent/productClass/productClass.vue'),
      },
      {
        path: 'class/addclass',
        name: 'addclass',
        hidden: true,
        meta: {
          title: '添加分类',
          permission: [0]
        },
        component: () => import('@/views/productMent/productClass/addclass.vue'),
      },
      {
        path: 'template',
        name: 'template',
        meta: {
          title: '参数模版',
          permission: [0]
        },
        component: () => import('@/views/productMent/productTemplate/productTemplate.vue'),
      },
      {
        path: 'template/addtemplate',
        name: 'addtemplate',
        hidden: true,
        meta: {
          title: '添加模版',
          permission: [0]
        },
        component: () => import('@/views/productMent/productTemplate/addtemplate.vue'),
      }
    ]
  },
  {
    path: '/article',
    hidden: true,
    meta: {
      permission: [0]
    },
    redirect: '/article/articleMent',
    component: adminLayout,
    children: [{
      path: 'articleMent',
      name: 'articleMent',
      meta: {
        title: '文章管理',
        icon: require('@/assets/images/icon/article-ment.png'),
        permission: [0]
      },
      component: () => import('@/views/articleMent/articleMent.vue'),
    }, {
      path: 'addarticleMent',
      name: 'addarticleMent',
      meta: {
        title: '文章管理',
        icon: require('@/assets/images/icon/article-ment.png'),
        permission: [0]
      },
      component: () => import('@/views/articleMent/addarticle.vue'),
    }]
  },
  {
    path: '/systemset',
    name: 'systemset',
    hidden: true,
    meta: {
      title: '系统设置',
      icon: require('@/assets/images/icon/system-settings.png'),
      permission: [0]
    },
    redirect: '/systemset/accountment',
    component: adminLayout,
    children: [{
        path: 'accountment',
        name: 'accountment',
        meta: {
          title: '账号管理',
          permission: [0]
        },
        component: () => import('@/views/systemSetting/accountMent.vue'),
      },
      
      {
        path: 'rolement',
        name: 'rolement',
        meta: {
          title: '角色管理',
          permission: [0]
        },
        component: () => import('@/views/systemSetting/roleMent.vue'),
      },
      {
        path: 'AttachmentMent',
        name: 'AttachmentMent',
        meta: {
          title: '附件管理',
          permission: [0]
        },
        component: () => import('@/views/AttachmentMent/AttachmentMent.vue'),
      },
      {
        path: 'addattach',
        name: 'addattach',
        hidden: true,
        meta: {
          title: '添加附件',
          permission: [0]
        },
        component: () => import('@/views/AttachmentMent/addattach.vue'),
      },
      {
        path: 'accountment/addaccount',
        name: 'addaccount',
        hidden: true,
        meta: {
          title: '添加账号',
          permission: [0]
        },
        component: () => import('@/views/systemSetting/addaccount.vue'),
      },
      {
        path: 'rolement/addrole',
        name: 'addrole',
        hidden: true,
        meta: {
          title: '添加角色',
          permission: [0]
        },
        component: () => import('@/views/systemSetting/addrole.vue'),
      },
      {
        path: 'settingHome/settingHome',
        name: 'settingHome',
        meta: {
          title: '小程序设置',
          permission: [0]
        },
        component: () => import('@/views/systemSetting/settingHome.vue'),
      }
    ]
  },
]

//商户
export const chantRouter = [{
    path: '/erchantMent',
    name: 'erchantMent',
    meta: {
      title: '商户管理',
      icon: require('@/assets/images/icon/merchant-manage.png'),
      permission: [1]
    },
    redirect: '/erchantMent/statistics',
    component: layout,
    children: [{
      path: 'statistics',
      name: 'statistics',
      meta: {
        title: '商户统计',
        permission: [1]
      },
      component: () => import('@/views/erchantMent/statistics/statistics.vue'),
    }, ]
  },
  {
    path: '/appletCenter',
    name: 'appletCenter',
    meta: {
      title: '小程序中心',
      icon: require('@/assets/images/icon/app-core.png'),
      permission: [1]
    },
    redirect: '/appletCenter/appMerchant',
    component: layout,
    children: [{
        path: 'appMerchant',
        name: 'appMerchant',
        meta: {
          title: '小程序商户',
          permission: [1]
        },
        component: () => import('@/views/appletCenter/appMerchant/appMerchant.vue'),
      },
      {
        path: 'recycleBin',
        name: 'recycleBin',
        meta: {
          title: '回收站',
          permission: [1]
        },
        component: () => import('@/views/appletCenter/recycleBin/recycleBin.vue'),
      }
    ]
  },
  {
    path: '/home',
    component: adminLayout,
    hidden: true,
    meta: {
      permission: [1],
    },
    redirect: '/home/dashboard',
    children: [{
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/homepage/homepage.vue'),
      meta: {
        title: '首页',
        icon: require('@/assets/images/icon/home-page.png'),
        permission: [1]
      }
    }]
  },
  {
    path: '/rotation',
    hidden: true,
    meta: {
      permission: [1]
    },
    redirect: '/rotation/rotation',
    component: adminLayout,
    children: [{
        path: 'rotation',
        name: 'rotation',
        meta: {
          title: '轮播图片',
          icon: require('@/assets/images/icon/rotation-chart.png'),
          permission: [1]
        },
        component: () => import('@/views/rotation/rotation.vue'),
      },
      {
        path: 'edit',
        hidden: true,
        name: 'edit',
        meta: {
          title: '轮播图修改',
          permission: [1]
        },
        component: () => import('@/views/rotation/editRata.vue')
      },
      {
        path: 'add',
        hidden: true,
        name: 'add',
        meta: {
          title: '轮播图添加',
          permission: [1]
        },
        component: () => import('@/views/rotation/addRata.vue')
      },
    ]
  },
  {
    path: '/formMent',
    name: 'formMent',
    hidden: true,
    meta: {
      title: '表单管理',
      icon: require('@/assets/images/icon/form-ment.png'),
      permission: [1]
    },
    redirect: '/formMent/formMent',
    component: adminLayout,
    children: [{
        path: 'formMent',
        name: 'formMent',
        meta: {
          title: '表单管理',
          permission: [1]
        },
        component: () => import('@/views/formMent/formMent.vue'),
      },
      {
        path: 'formRecord',
        name: 'formRecord',
        meta: {
          title: '表单记录',
          permission: [1]
        },
        component: () => import('@/views/formMent/formRecord.vue'),
      },
      {
        path: 'editform',
        name: 'editform',
        hidden: true,
        meta: {
          title: '表单编辑',
          permission: [1]
        },
        component: () => import('@/views/formMent/editform.vue')
      },
    ]
  },
  {
    path: '/productMent',
    name: 'productMent',
    hidden: true,
    meta: {
      title: '产品管理',
      icon: require('@/assets/images/icon/product-ment.png'),
      permission: [1]
    },
    redirect: '/productMent/list',
    component: adminLayout,
    children: [{
        path: 'list',
        name: 'list',
        meta: {
          title: '产品列表',
          permission: [1]
        },
        component: () => import('@/views/productMent/productList/productList.vue'),
      },
      {
        path: 'list/addlist',
        name: 'addlist',
        hidden: true,
        meta: {
          title: '添加产品',
          permission: [1]
        },
        component: () => import('@/views/productMent/productList/productAdd.vue'),
      },
      {
        path: 'class',
        name: 'class',
        meta: {
          title: '产品分类',
          permission: [1]
        },
        component: () => import('@/views/productMent/productClass/productClass.vue'),
      },
      {
        path: 'class/addclass',
        name: 'addclass',
        hidden: true,
        meta: {
          title: '添加分类',
          permission: [1]
        },
        component: () => import('@/views/productMent/productClass/addclass.vue'),
      },
      {
        path: 'template',
        name: 'template',
        meta: {
          title: '参数模版',
          permission: [1]
        },
        component: () => import('@/views/productMent/productTemplate/productTemplate.vue'),
      },
      {
        path: 'template/addtemplate',
        name: 'addtemplate',
        hidden: true,
        meta: {
          title: '添加模版',
          permission: [1]
        },
        component: () => import('@/views/productMent/productTemplate/addtemplate.vue'),
      }
    ]
  },
  {
    path: '/article',
    hidden: true,
    meta: {
      permission: [1]
    },
    redirect: '/article/articleMent',
    component: adminLayout,
    children: [{
      path: 'articleMent',
      name: 'articleMent',
      meta: {
        title: '文章管理',
        icon: require('@/assets/images/icon/article-ment.png'),
        permission: [1]
      },
      component: () => import('@/views/articleMent/articleMent.vue'),
    }, {
      path: 'addarticleMent',
      name: 'addarticleMent',
      meta: {
        title: '文章管理',
        icon: require('@/assets/images/icon/article-ment.png'),
        permission: [1]
      },
      component: () => import('@/views/articleMent/addarticle.vue'),
    }]
  },
  {
    path: '/systemset',
    name: 'systemset',
    hidden: true,
    meta: {
      title: '系统设置',
      icon: require('@/assets/images/icon/system-settings.png'),
      permission: [1]
    },
    redirect: '/systemset/accountment',
    component: adminLayout,
    children: [{
        path: 'accountment',
        name: 'accountment',
        meta: {
          title: '账号管理',
          permission: [1]
        },
        component: () => import('@/views/systemSetting/accountMent.vue'),
      },

      {
        path: 'rolement',
        name: 'rolement',
        meta: {
          title: '角色管理',
          permission: [1]
        },
        component: () => import('@/views/systemSetting/roleMent.vue'),
      },
      {
        path: 'AttachmentMent',
        name: 'AttachmentMent',
        meta: {
          title: '附件管理',
          permission: [1]
        },
        component: () => import('@/views/AttachmentMent/AttachmentMent.vue'),
      },
      {
        path: 'addattach',
        name: 'addattach',
        hidden: true,
        meta: {
          title: '添加附件',
          permission: [1]
        },
        component: () => import('@/views/AttachmentMent/addattach.vue'),
      },
     

      {
        path: 'accountment/addaccount',
        name: 'addaccount',
        hidden: true,
        meta: {
          title: '添加账号',
          permission: [1]
        },
        component: () => import('@/views/systemSetting/addaccount.vue'),
      },
      {
        path: 'rolement/addrole',
        name: 'addrole',
        hidden: true,
        meta: {
          title: '添加角色',
          permission: [1]
        },
        component: () => import('@/views/systemSetting/addrole.vue'),
      },
      {
        path: 'settingHome/settingHome',
        name: 'settingHome',
        meta: {
          title: '小程序设置',
          permission: [1]
        },
        component: () => import('@/views/systemSetting/settingHome.vue'),
      }
    ]
  },
]







// 员工路由
export const merRouter = [{
    path: '/home',
    component: layout,
    meta: {
      permission: [2, 3],
    },
    redirect: '/home/dashboard',
    children: [{
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/homepage/homepage.vue'),
      meta: {
        title: '首页',
        icon: require('@/assets/images/icon/home-page.png'),
        permission: [2, 3]
      }
    }]
  },
  {
    path: '/rotation',
    meta: {
      permission: [2, 3]
    },
    redirect: '/rotation/rotation',
    component: layout,
    children: [{
        path: 'rotation',
        name: 'rotation',
        meta: {
          title: '轮播图片',
          icon: require('@/assets/images/icon/rotation-chart.png'),
          permission: [2, 3]
        },
        component: () => import('@/views/rotation/rotation.vue'),
      },
      {
        path: 'edit',
        hidden: true,
        name: 'edit',
        meta: {
          title: '轮播图修改',
          permission: [2, 3]
        },
        component: () => import('@/views/rotation/editRata.vue')
      },
      {
        path: 'add',
        hidden: true,
        name: 'add',
        meta: {
          title: '轮播图添加',
          permission: [2, 3]
        },
        component: () => import('@/views/rotation/addRata.vue')
      },
    ]
  },
  {
    path: '/formMent',
    name: 'formMent',
    meta: {
      title: '表单管理',
      icon: require('@/assets/images/icon/form-ment.png'),
      permission: [2, 3]
    },
    redirect: '/formMent/formMent',
    component: layout,
    children: [{
        path: 'formMent',
        name: 'formMent',
        meta: {
          title: '表单管理',
          permission: [2, 3]
        },
        component: () => import('@/views/formMent/formMent.vue'),
      },
      {
        path: 'formRecord',
        name: 'formRecord',
        meta: {
          title: '表单记录',
          permission: [2, 3]
        },
        component: () => import('@/views/formMent/formRecord.vue'),
      },
      {
        path: 'editform',
        name: 'editform',
        hidden: true,
        meta: {
          title: '表单编辑',
          permission: [2, 3]
        },
        component: () => import('@/views/formMent/editform.vue')
      },
    ]
  },
  {
    path: '/productMent',
    name: 'productMent',
    meta: {
      title: '产品管理',
      icon: require('@/assets/images/icon/product-ment.png'),
      permission: [2, 3]
    },
    redirect: '/productMent/list',
    component: layout,
    children: [{
        path: 'list',
        name: 'list',
        meta: {
          title: '产品列表',
          permission: [2, 3]
        },
        component: () => import('@/views/productMent/productList/productList.vue'),
      },
      {
        path: 'list/addlist',
        name: 'addlist',
        hidden: true,
        meta: {
          title: '添加产品',
          permission: [2, 3]
        },
        component: () => import('@/views/productMent/productList/productAdd.vue'),
      },
      {
        path: 'class',
        name: 'class',
        meta: {
          title: '产品分类',
          permission: [2, 3]
        },
        component: () => import('@/views/productMent/productClass/productClass.vue'),
      },
      {
        path: 'class/addclass',
        name: 'addclass',
        hidden: true,
        meta: {
          title: '添加分类',
          permission: [2, 3]
        },
        component: () => import('@/views/productMent/productClass/addclass.vue'),
      },
      {
        path: 'template',
        name: 'template',
        meta: {
          title: '参数模版',
          permission: [2, 3]
        },
        component: () => import('@/views/productMent/productTemplate/productTemplate.vue'),
      },
      {
        path: 'template/addtemplate',
        name: 'addtemplate',
        hidden: true,
        meta: {
          title: '添加模版',
          permission: [2, 3]
        },
        component: () => import('@/views/productMent/productTemplate/addtemplate.vue'),
      }
    ]
  },
  {
    path: '/article',
    meta: {
      permission: [2, 3]
    },
    redirect: '/article/articleMent',
    component: layout,
    children: [{
      path: 'articleMent',
      name: 'articleMent',
      meta: {
        title: '文章管理',
        icon: require('@/assets/images/icon/article-ment.png'),
        permission: [2, 3]
      },
      component: () => import('@/views/articleMent/articleMent.vue'),
    }, {
      path: 'addarticleMent',
      name: 'addarticleMent',
      meta: {
        title: '添加文章',
        icon: require('@/assets/images/icon/article-ment.png'),
        permission: [2, 3]
      },
      component: () => import('@/views/articleMent/addarticle.vue'),
    }]
  }
]