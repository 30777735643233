<!--
 * @Author: 喀喀喀 1246444836@qq.com
 * @Date: 2022-07-18 09:00:21
 * @LastEditors: 喀喀喀 1246444836@qq.com
 * @LastEditTime: 2022-07-18 14:37:41
 * @FilePath: /xinxihoutai/src/views/adminLayout/adminLayout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
        <div class="wrapper">
            <el-container>
                <el-header>
                    <!-- 头部区域 -->
                    <topBar :userName="userName"></topBar>
                </el-header>
                <el-container class="bggrey">
                    <!-- 侧边栏区域 -->
                    <el-aside>
                        <silderBar :perRouterList="perRouterList"></silderBar>
                    </el-aside>
                    <!-- 主体区域 -->
                    <el-main>
                        <router-view></router-view>
                    </el-main>
                </el-container>
            </el-container>
        </div>
    </div>
</template>

<script>
    import topBar from '@/components/topBar/topBar.vue'
    import silderBar from '@/components/silderBar'
    import {router1,chantRouter} from '@/router/modules/routerData.js'
    import {sidebarHidden} from '@/utils/permissionRouter.js'
    import {sessionGetKey} from '@/utils/sessionStorage.js'
    import deepCopy from '@/utils/deepCopy'
    export default {
        components: {
            topBar,
            silderBar
        },
        data() {
            return {
                perRouterList: [],
                userName:''
            }
        },
        mounted() {
            this.getsilderBar()
            this.getHeader()
        },
        methods: {
            getsilderBar() {
                const roleType = sessionGetKey('role')
                if(roleType == 0){
                    let list = sidebarHidden(router1, roleType)
                    let bar = deepCopy(list)
                    for(let item of bar){
                        delete item.hidden
                    }
                    this.perRouterList = JSON.parse(JSON.stringify(bar))
                    console.log(this.perRouterList, 'this.perRouterList')
                }else if(roleType == 1){
                    let list = sidebarHidden(chantRouter, roleType)
                    let bar = deepCopy(list)
                    for(let item of bar){
                        delete item.hidden
                    }
                    this.perRouterList = JSON.parse(JSON.stringify(bar))
                }
                console.log(this.perRouterList, 'admin.perRouterList')
            },
            getHeader(){
                const userName = sessionGetKey('userName')
                if(userName){
                    this.userName = userName
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .el-header {
        padding: 0;
        height: 88px !important;
    }

    .bggrey {
        background-color: #f3f3f3;
    }

    .el-aside {
        // background-color: red;
        height: calc(100vh - 88px);
        width: 230px !important;
        background-color: #1e2662;
    }

    /* 让滚动条隐藏 */
    .el-aside::-webkit-scrollbar {
        width: 0 !important
    }

    .el-main {
        background-color: #F5F6FA;
        padding: 0;
        height: calc(100vh - 88px);
    }
</style>