// 根据用户角色，动态的计算出权限路由
export const permissionRouter = function (router, roleType) {
    return router.filter(item => {
        if (item.children) {
            item.children = permissionRouter(item.children, roleType)
        }
        return item.meta.permission.includes(roleType)
    })
}

// 计算要渲染的侧边栏，如果有hidden为true,就不加进去
export const sidebarDisplay = function (router, roleType) {
    return router.filter(item => {
        if (item.hidden !== true) {
            if (item.children) {
                item.children = sidebarDisplay(item.children, roleType)
            }
            return item.meta.permission.includes(roleType)
        }
    })
}

// 计算要渲染的侧边栏，hidden为true,加进去
export const sidebarHidden = function (router, roleType) {
    return router.filter(item => {
        if (item.hidden === true) {
            // console.log(item, 'item')
            if (item.children) {
                item.children = sidebarDisplay(item.children, roleType)
            }
            return item.meta.permission.includes(roleType)
        }
    })
}