<template>
  <div class="menu-item"  @click="clickEvent(child)" v-if="!child.hidden" :class="[{'current-menu':contrastPath},{'level-vertical-center':only!=='only'},{'only-sty':only==='only'}]" :style="{'background-color':only==='only'?'#1D2566':''}">
    <img v-if="child.meta.icon" :src="child.meta.icon">
    <span :style="{'color':only==='only'?'#ffffff':''}">{{child.meta.title}}</span>
  </div>
</template>

<script>
  import path from 'path'
  export default {
    props: {
      child: {
        type: Object
      },
      basePath: {
        type: String
      },
      only:{
        type:String
      }
    },
    mounted(){
      // console.log(this.child.meta.icon,'child')
    },
    methods: {
      compilePath(childpath) {
        return path.resolve(this.basePath, childpath)
      },
      // 路由跳转
      clickEvent(child) {
        const path = this.compilePath(child.path)
        this.$router.push(path)
      }
    },
    computed:{
      contrastPath() {
        const path = this.$route.path
        if(path.indexOf(this.compilePath(this.child.path)) !== -1){
          return true
        }else {
          return false
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .menu-item{
    height: 50px;
    background-color: #020512;
    img{
      vertical-align:middle;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span{
      vertical-align:middle;
      color: #999999;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .current-menu{
    background-image: linear-gradient(to right, #4E87FF, #4458FE);
    span{
      color: #FFFFFF;
    }
  }
  
  .only-sty{
    display: flex;
    align-items: center;
    padding:0 15px;
  }

  .menu-item:hover{
    
  }
</style>