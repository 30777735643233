//深拷贝

//获取数据的具体的数据类型，并且返回
function getType(data) {
    return Object.prototype.toString.call(data).slice(8, -1)
}


function deepCopy(data) {
    if(getType(data) == "Array"){
        var res = [];
    }else if(getType(data) == "Object"){
        var res = {};
    }
    for(var key in data){
        if(getType(data[key]) == "Array" || getType(data[key]) == "Object"){
            res[key] = deepCopy(data[key]);
        }else{
            res[key] = data[key];
        }
    }
    return res;
}

export default deepCopy