<template>
  <!-- 分页 -->
  <div class="pagination-box" v-if="pagination.total>pagination.pageSize">
    <div class="page" @click="currentpageclcik(1)">首页</div>
    <el-pagination
      @prev-click="currentpageclcik"
      @next-click="currentpageclcik"
      @current-change="currentpageclcik"
      :current-page="currentpage"
      background
      prev-text="上一页"
      :hide-on-single-page="true"
      next-text="下一页"
      layout="prev, pager, next"
      :total="pagination.total"
      :page-size="pagination.pageSize"
    >
    </el-pagination>
    <div class="page" @click="currentpageclcik(pagination.totalpage, '末页')">
      末页
    </div>
    <div class="font-page totalpage">共{{ pagination.totalpage }}页</div>
    <div class="font-page current-page">
      <div class="cpage">
        第 <el-input v-model="currentpage" placeholder=""></el-input>页
      </div>
      <el-button
        size="mini"
        class="gobtn"
        @click="currentpageclcik(currentpage)"
        >GO</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentpage: 1,
    };
  },
  props: {
    pagination: {
      type: Object,
      default() {
        return {
          currentpage: 1,
          total: 100,
          totalpage: 10,
          pageSize: 5,
        };
      },
    },
  },
  watch: {
    pagination: {
      handler(val) {},
      immediate: true,
      deep: true,
    },
    currentpage: {
      handler(val) {
        this.currentpage = Number(val);
      },
    },
  },
  methods: {
    currentpageclcik(e) {
      this.currentpage = Number(e);

      this.$emit("pageclcik", Number(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .totalpage {
    margin-left: 30px;
    margin-right: 100px;
  }
  .current-page {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .cpage {
      display: flex;
      align-items: center;
      /deep/.el-input__inner {
        margin: 0 10px;
        text-align: center;
        width: 50px;
        height: 30px;
        padding: 3px;
      }
    }
  }
  .font-page {
    color: #333333;
    font-size: 14px;
  }
  .el-pagination {
    padding: 2px 10px;
  }
  .gobtn {
    width: 75px !important;
    min-width: 75px !important;
    background-color: #4458fe;
    color: #fff;
    margin-left: 25px;
  }
  .page:active {
    background: #4458fe;
    color: #fff;
  }
  .page {
    cursor: pointer;
    width: 70px !important;
    min-width: 70px !important;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #303133;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #999;
    height: 28px;
    line-height: 28px;
    border-radius: 3px;
  }
  /deep/.btn-prev {
    text-align: center;
    padding: 0 10px;
    width: 70px !important;
    min-width: 70px !important;
    background-color: #fff;
    border: 1px solid #999;
  }
  /deep/.btn-next {
    text-align: center;
    width: 70px !important;
    min-width: 70px !important;
    padding: 0 10px;
    background-color: #fff;
    border: 1px solid #999;
  }
  /deep/.el-icon {
    background: #fff !important;
  }
  /deep/.el-pager {
    // min-width: 522px;
    // max-width: 542px;
    .number {
      width: 50px !important;
      min-width: 50px !important;
      background-color: #fff;
      border: 1px solid #999;
      border-radius: 3px;
      margin: 0 8px;
    }
  }
}
</style>