import {
    decrypt,
    encrypt
} from './encry.js'

const config = {
    type: 'localStorage', //存储类型，localStorage | sessionStorage
    prefix: 'X', //版本号
    expire: 24 * 60, //过期时间，默认为一天，单位为分钟
    isEncrypt: true, //支持加密、解密数据处理
};


//setStorage 设置值
const setStorage = (key, value, expire = 24 * 60) => {
    if (value === '' || value === null || value === undefined) {
        //空值重置
        value = null;
    }
    if (isNaN(expire) || expire < 0) {
        //过期时间值合理性判断
        throw new Error('Expire must be a number');
    }
    const data = {
        value, //存储值
        time: Date.now(), //存储日期
        expire: Date.now() + 1000 * 60 * expire, //过期时间
    };
    //是否需要加密，判断装载加密数据或原数据
    window[config.type].setItem(
        autoAddPreFix(key),
        config.isEncrypt ? encrypt(JSON.stringify(data)) : JSON.stringify(data),
    );
    return true;
};


// getStorageFromKey 根据key获取value
const getStorageFromKey = (key) => {
    //获取指定值
    if (config.prefix) {
        key = autoAddPreFix(key);
    }
    if (!window[config.type].getItem(key)) {
        //不存在判断
        return null;
    }
    const storageVal = config.isEncrypt ?
        JSON.parse(decrypt(window[config.type].getItem(key))) :
        JSON.parse(window[config.type].getItem(key));
    const now = Date.now();
    if (now >= storageVal.expire) {
        //过期销毁
        removeStorageFromKey(key);
        return null;
        //不过期回值
    } else {
        return storageVal.value;
    }
};

// getAllStorage 获取所有存储值
const getAllStorage = () => {
    //获取所有值
    const storageList = {};
    const keys = Object.keys(window[config.type]);
    keys.forEach((key) => {
        const value = getStorageFromKey(key);
        if (value !== null) {
            //如果值没有过期，加入到列表中
            storageList[key] = value;
        }
    });
    return storageList;
};

// getStorageLength 获取存储值数量
const getStorageLength = () => {
    //获取值列表长度
    return window[config.type].length;
};

// removeStorageFromKey 根据key删除存储值
const removeStorageFromKey = (key) => {
    //删除值
    if (config.prefix) {
        key = autoAddPreFix(key);
    }
    window[config.type].removeItem(key);
};

// clearStorage 清空存储列表
const clearStorage = () => {
    window[config.type].clear();
};

// autoAddPreFix 基于全局配置的prefix参数添加前缀
const autoAddPreFix = (key) => {
    //添加前缀，保持浏览器Application视图唯一性
    const prefix = config.prefix || '';
    return `${prefix}_${key}`;
};


export {
    setStorage,
    getStorageFromKey,
    getAllStorage,
    getStorageLength,
    removeStorageFromKey,
    clearStorage,
};